import React, { useContext } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import '../components/menu/menu.scss'
import SEO from '../components/seo'
import '../styles/global.css'
import '../components/postslist/postslist.scss'
import  ThemeContext  from '../components/context/ThemeContext';
import switchCategory from '../utils/switchCategory.jsx';
import Helmet from "react-helmet"
import "./five_ch-post.scss"

function IndexPage( five_ch,  twitter) {
  const allDataInDesc = [
    ...five_ch.map(e => {
      return(e)}),
    ...twitter.map(e => {
    return(e)}),
  ].sort((a, b) => { 
      return new Date(a.date) > new Date(b.date) ? -1 : 1; });
  return allDataInDesc
}


export default ({
  location ,data,pageContext}) => {
        const {strict_genreTag} = pageContext
        const { five_ch, twitter } = data;

        var num = 0
        var tmp = 0

        if (location.state === null || location.state === undefined) {
          num = 0
        }
        else {
          num = location.state.tabnumber
        }

      return (
      <>
        <Helmet
            bodyAttributes={{
            class: 'globalnody'
            }}
        />
        <ThemeContext.Consumer>
            {theme => (
                <Layout page="posts" tabnumber={num} themeContext={theme} template='five_ch'>
                    <SEO title="話題に反応！" description="2chやTwitterなどで話題のニュースについてまとめたサイト" />
                        <main className="postslist--main">
                            <h2>{strict_genreTag}に関する記事</h2>
                            <div className="postslist--article__lists">
                            {
                            IndexPage(five_ch.nodes, twitter.nodes)
                            .map((node) => {
                                return (
                                    <article className="postslist--onearticle">
                                        <Link to={node.fields.slug} className="postslist--onearticle__link" state={{ tabnumber: switchCategory(node.categories) }}>
                                            <div className="postslist--onearticle__image">
                                            <img
                                            src ={node.top_image}
                                            className="postslist--onearticle__image__img"
                                            />
                                            </div>
                                            <div className="postslist--onearticle-info">
                                            <h2 className="postslist--onearticle__title">
                                                {node.title}
                                                </h2>
                                            </div>
                                        </Link>
                                    </article>
                                )
                                })
                            }
                            </div>
                        </main>
                </Layout>
            )}
        </ThemeContext.Consumer>
    </>
      )
}

export const query = graphql`
query($strict_genreTag: String!)  {
  five_ch : allFiveChJson(filter: {genre: {elemMatch: {strict_genre: {eq: $strict_genreTag}}}}) {
          nodes {
          title
          date(formatString: "YYYY.MM.DD h:mm")
          categories
          genre{
            rough_genre
            strict_genre
          }
          fields {
              slug
            }
          top_image
            url
            ad_words_all
        }
      }

      twitter : allTwitterJson(filter: {genre: {elemMatch: {strict_genre: {eq: $strict_genreTag}}}}) {
        nodes {
        title
        date(formatString: "YYYY.MM.DD h:mm")
        categories
        genre{
          rough_genre
          strict_genre
        }
        fields {
            slug
          }
        top_image
          
          ad_words_all
      }
    }
  }
`